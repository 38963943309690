@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
*, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: ease-in-out 0s;
  cursor: none;
}

body {
  background-color: #1a2232;
  color: white;
  font-family: "Open sans", sans-serif;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.nav_links a:hover {
  text-decoration: underline;
}

.social_links {
  color: #a9d1fb;
}
.social_links span {
  color: white;
}

.single_project:hover {
  animation-name: hh;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

@media (max-width: 1000px) {
  .render {
    display: flex !important;
    flex-direction: column !important;
  }
}
@keyframes projectHover {
  0% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(0.89);
  }
  75% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor__inner {
  /*  position: absolute;
   top: 50%;
   left: 50%; */
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}